<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Evaluate <strong>{{ startupTitle }}</strong> for <strong>{{ assignmentTitle }}</strong>
            </h3>
          </b-card-header>
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row
              v-for="(section, i) in questions"
              :key="i"
            >
              <b-col v-if="i !== 'null'"><h4 class="p-1 mb-2 rounded bg-light">
                {{ i }}
              </h4></b-col>
              <b-col
                class="px-2"
                cols="12"
              >
                <b-row
                  v-for="(question, index) in section"
                  :key="index"
                >
                  <b-col>
                    <b-form-group
                      :label="`Q${index+1}. ${question.question}`"
                      :label-for="`question-${index}`"
                      label-class="h5"
                    >
                      <h6 class="font-italic">
                        A. {{ question.programs_startupresponsetables[0].answer }}
                      </h6>
                    </b-form-group>
                    <b-form-group
                      :label-for="`score-${index}`"
                      label="Score"
                    >
                      <b-form-rating
                        :id="`score-${index}`"
                        v-model="question.programs_startupresponsetables[0].programs_assignmentdirectscores[0].score"
                        v-b-tooltip.focus.right
                        :title="readonly ? 'Evaluation is disabled' : ''"
                        :readonly="readonly"
                        :variant="readonly ? '' : 'primary'"
                        inline
                        required
                        show-value
                        show-value-max
                        stars="10"
                      />
                      <b-form-group
                        v-if="question.programs_startupresponsetables[0] && question.programs_startupresponsetables[0].programs_assignmentdirectscores[0].score"
                        :label-for="`comment-${index}`"
                        label="Comments"
                      >
                        <b-form-textarea
                          v-model="question.programs_startupresponsetables[0].programs_assignmentdirectscores[0].comment"
                          placeholder="Comments (Optional)"
                          max-rows="6"
                          :disabled="readonly"
                        />
                      </b-form-group>
                    </b-form-group>
                    <hr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="px-2 d-flex align-items-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="readonly"
                  class="mr-2 mt-1 py-1"
                  variant="primary"
                  @click="$bvModal.show('recommendationModal')"
                >
                  <span>Update Score</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-overlay>
      <b-modal
        v-if="recommendationData"
        id="recommendationModal"
        hide-header-close
        ok-only
        ok-title="Submit"
        title="Recommendations"
        @ok="updateAnswer"
      >
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="recommendationData.recommendations"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="recommendationData.verdict"
            :options="['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRating,
  BFormTextarea,
  BIconBoxArrowUpRight,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRating,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    ToastificationContent,
    BCard,
    BCardHeader,
    BFormTextarea,
    BIconBoxArrowUpRight,

  },
  data() {
    return {
      title: '',
      review_status: 'disabled',
      assignmentData: '',
      mutationLoading: false,
      startupTitle: '',
      assignmentTitle: '',
    }
  },
  computed: {
    readonly() {
      return this.review_status !== 'enabled' || this.$route.query.readonly === 'true'
    },
    questions() {
      return this.groupByKey(this.assignmentData, 'section')
    },
    /* eslint-disable no-param-reassign,no-underscore-dangle */
    scores() {
      const arr = []
      this.assignmentData.map(r => {
        const res = { ...r.programs_startupresponsetables[0] }
        // res.programs_assignmentdirectscores[0].partner_id = res.programs_assignmentdirectscores[0].partner_id || this.$route.params.rid
        return arr.push(res)
      })
      arr.forEach(x => {
        x.programs_assignmentdirectscores = {
          on_conflict: {
            constraint: 'programs_assignmentdirectscores_pkey',
            update_columns: ['score', 'comment'],
          },
          data: x.programs_assignmentdirectscores,
        }
      })
      return arr
    },
  },
  methods: {
    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    updateAnswer() {
      this.mutationLoading = true
      this.recommendationData.participant_score = (this.scores.reduce((previousValue, currentValue) => previousValue + Number.parseInt(currentValue.programs_assignmentdirectscores.data[0].score, 10), 0) / this.scores.length).toFixed(2)
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_startupresponsetable_insert_input!]!, $object: programs_scoretable_insert_input!) {
          insert_programs_startupresponsetable(objects: $answers, on_conflict: {constraint: programs_responsetable_pkey, update_columns: score}) {
            affected_rows
          }
          insert_programs_scoretable_one(object: $object, on_conflict: {constraint: programs_scoretable_pkey, update_columns: [recommendations, verdict]}) {
            id
          }
        }`,
        variables: {
          answers: this.scores,
          object: this.recommendationData,
        },
        update: (store, { data: { insert_programs_startupresponsetable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_startupresponsetable.affected_rows ? 'Scores submitted successfully' : 'Failed to submit scores',
              icon: insert_programs_startupresponsetable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_startupresponsetable.affected_rows ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    startupTitle: {
      query() {
        return gql`
          {
              programs_startupparticipants_by_pk(id: ${this.$route.params.aid}) {
                programs_applicantstable {
                  users_organizationtable {
                    title
                  }
                }
              }
            }
        `
      },
      update(data) {
        return data.programs_startupparticipants_by_pk.programs_applicantstable.users_organizationtable.title
      },
    },
    assignmentData: {
      query() {
        return gql`
        {
          programs_assignmenttable_by_pk(id: ${this.$route.params.apid}) {
            review_status
            title
            programs_assignmentquestionstables {
              id
              input_type
              question
              section
              programs_startupresponsetables(where: {participant_id: {_eq: ${this.$route.params.aid}}}) {
                answer
                id
                participant_id
                programs_assignmentdirectscores(where: {partner_id: {_eq: ${this.$route.params.rid}}}) {
                  id
                  score
                  comment
                  partner_id
                }
              }
            }
          }
        }`
      },
      update(data) {
        this.assignmentTitle = data.programs_assignmenttable_by_pk.title
        this.review_status = data.programs_assignmenttable_by_pk.review_status
        const response = data.programs_assignmenttable_by_pk.programs_assignmentquestionstables
        response.forEach(e => {
          if (e.programs_startupresponsetables[0].programs_assignmentdirectscores.length < 1) {
            e.programs_startupresponsetables[0].programs_assignmentdirectscores.push({
              score: null,
              comment: null,
              partner_id: this.$route.params.rid,
            })
          }
        })
        return response
      },
    },
    recommendationData: {
      query() {
        return gql`
        {
          programs_scoretable(where: {participant_id: {_eq: ${this.$route.params.aid}}, assignment_id: {_eq: ${this.$route.params.apid}}, partner_id: {_eq: ${this.$route.params.rid}}}) {
            id
            verdict
            participant_score
            recommendations
          }
        }`
      },
      update(data) {
        return data.programs_scoretable[0] || {
          participant_id: this.$route.params.aid,
          assignment_id: this.$route.params.apid,
          partner_id: this.$route.params.rid,
          verdict: '',
          recommendations: '',
          participant_score: 0,
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
